import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "unitPriceFormatText", "itemWeightField" ]

  initialize() {
    // If Possible find a way to DRY up select_tag/buying format setting so it's only declared once
    let select_tag = document.getElementById('product_buying_format')
    let buying_format = select_tag.options[select_tag.selectedIndex].dataset.format
    this.set_form_fields(buying_format)
  }

  update_form_fields(event) {
    let select_tag = event.target.closest("select")
    let buying_format = select_tag.options[select_tag.selectedIndex].dataset.format
    this.set_form_fields(buying_format)

    if (buying_format == "quantity"){
      document.getElementById('product_approximate_item_weight').value = "";
    }
  }

  set_form_fields(buying_format) {
    switch (buying_format) {
      case "quantity":
        this.unitPriceFormatTextTarget.innerHTML = "each"
        this.itemWeightFieldTarget.style.display = "none"
        break;
      case "weight":
        this.unitPriceFormatTextTarget.innerHTML = "kg"
        this.itemWeightFieldTarget.style.display = "block"
    }
  }
}

import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "subtotalAmount", "deliveryFee", "totalAmount", "deliveryDetails", "selectedDate", "gstIncludedInTotal"]

  initialize() {
    this.toggle_delivery_form()
    this.set_prices()
    this.selectedDateTarget.style.display = "none"
  }

  update(event) {
    this.toggle_delivery_form()
    this.set_prices()
    this.selectedDateTarget.style.display = "none"
  }

  show_selected_date_text(event) {
    let date = event.currentTarget.parentElement.textContent
    console.log(date)
    this.selectedDateTarget.style.display = "block"
    this.selectedDateTarget.innerHTML = `Order will be fulfiled on <strong>${date}</strong>`
  }

  set_prices() {
    let select_tag = document.getElementById('order_submitting_delivery_zone_id')
    let subtotal_cents = this.subtotalAmountTarget.dataset.subtotal
    let delivery_fee_cents = select_tag.options[select_tag.selectedIndex].dataset.fee

    if (delivery_fee_cents == null){
      delivery_fee_cents = 0
    }

    let delivery_fee_curreny_format = (delivery_fee_cents / 100).toLocaleString("en-AU", {style:"currency", currency:"AUD"})
    let total_amount_curreny_format = ((delivery_fee_cents / 100) + (subtotal_cents / 100)).toLocaleString("en-AU", {style:"currency", currency:"AUD"})
    this.deliveryFeeTarget.innerHTML = delivery_fee_curreny_format
    this.totalAmountTarget.innerHTML = total_amount_curreny_format

    // Set GST Included in Total
    let subtotal_gst_cents = this.subtotalAmountTarget.dataset.gst
    let delivery_gst_cents = select_tag.options[select_tag.selectedIndex].dataset.gst

    if (delivery_gst_cents == null){
      delivery_gst_cents = 0
    }

    this.gstIncludedInTotalTarget.innerHTML = ((subtotal_gst_cents / 100) + (delivery_gst_cents / 100)).toLocaleString("en-AU", {style:"currency", currency:"AUD"})
  }

  hide_delivery_form() {
    this.deliveryDetailsTarget.style.display = "none"
    document.getElementById('order_delivery_street_address').value = "";
    document.getElementById('order_delivery_suburb').value = "";
    document.getElementById('order_delivery_postcode').value = "";
    document.getElementById('order_delivery_state').value = "";
    document.getElementById('order_delivery_instructions').value = "";
  }

  show_delivery_form() {
    this.deliveryDetailsTarget.style.display = "block"
  }

  toggle_delivery_form() {
    let select_tag = document.getElementById('order_submitting_delivery_zone_id')
    let delivery_kind = select_tag.options[select_tag.selectedIndex].dataset.kind

    switch (delivery_kind) {
      case "collection":
        this.hide_delivery_form()
        break;
      case "delivery":
        this.show_delivery_form()
        break;
      default:
        this.hide_delivery_form()
    }
  }
}

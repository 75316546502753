import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "cartSummaryCard" ]

  scroll_to_cart_summary(event) {
    event.preventDefault()
    this.cartSummaryCardTarget.scrollIntoView()
  }
}

import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "links", "template" ]

  add_association(event) {
    event.preventDefault()

    var content = this.templateTarget.innerHTML.replace(/NEW_RECORD/g, new Date().getTime())
    this.linksTarget.insertAdjacentHTML("beforebegin", content) // Inserted before target div

    $('[data-select="select2"]').select2({
      theme: "bootstrap"
    });
  }

  remove_association(event) {
    event.preventDefault()

    let wrapper = event.target.closest(".nested-fields")
    if (wrapper.dataset.newRecord == "true") {
      wrapper.remove()
    } else {
      wrapper.querySelector("input[name*='_destroy']").value = 1
      wrapper.style.display = "none"
    }
  }

  set_quantity_step(event) {
    let wrapper = event.target.closest(".nested-fields")
    let select_tag = event.target.closest("select")
    let selected_quantity_step = select_tag.options[select_tag.selectedIndex].dataset.quantityStep
    let qty_input = wrapper.querySelector(".quantity")

    if (selected_quantity_step != null) {
      qty_input.value = selected_quantity_step
      qty_input.step = selected_quantity_step
    }
  }
}
